
var $            = require('jquery');
var $html        = $('html');
var gsap         = require("gsap");
var BezierEasing = require("bezier-easing");
var when         = require('when');
var helpers      = require('./helpers');

var TweenMax     = gsap.TweenMax;
var TimelineLite = gsap.TimelineLite;
var TimelineMax  = gsap.TimelineMax;

var $html        = $('html');
var $preloader   = $('.preloader');


var stopVideoBgs = function(){
  $('.video-bg video').each(function(){
    this.pause();
    this.currentTime = 0;
  });
}

module.exports.in = function(next){
  //console.log("transitioning in");
  $html.removeClass('transitioning');
  if(typeof next == 'function'){
    next();
  }
};

//hide current page and transition
module.exports.out = function(next){
  //console.log("transitioning out");
  $html.addClass('transitioning');
  setTimeout(stopVideoBgs, 1000);

  var title_easing = BezierEasing(0.25, 0.1, 0.0, 1.0);
  //var logo_easing = BezierEasing(.4,.04,.49,1.78);
  //var mainTl = new TimelineMax({paused: false,  delay: 1, ease:new Ease(title_easing)});
  var mainTl = new TimelineMax({
    delay: 1.1,
    paused: false,  
    //delay: 0, 
    ease:Power0.easeNone, 
    onComplete: function() {
      //check if we can stop transitioning
      if(window.Roma.contentLoading){
        mainTl.restart();
      }else{
        $html.removeClass('transitioning');
        if(typeof next == 'function'){
          next();
        }
      }
    }
  });

  var boxTimelines = [];
  //randomly select boxes so they not always animate in a linear fashion
  var $boxes = helpers.shuffle($( '.pl-box' ));

  $boxes.each( function(i) {
    var $currentBox = $(this);
    var tlBox = new TimelineMax({paused: false});
    var $sides = $currentBox.find('.side');
    boxTimelines.push(tlBox);

    //add box sides to the timeline
    $sides.each(function(){
      var $side = $(this);
        
      tlBox
        .set($side, {opacity: 0.8, scaleX: 0, scaleY: 0})

      tlBox
        .to( $side, 0.1, { opacity: 1, ease: Power0.easeNone })  
        .to( $side, 0.2, { scaleY: 1, scaleX: 1, ease: Power0.easeNone }, '-=0.1');
    });

    var random_overlap = 0.5;
    
    //add box timleine to main timeline
    mainTl
      .add(tlBox, '-=' + random_overlap);
  });

  mainTl
    .addLabel('boxescomplete');

  var $randomSides = helpers.shuffle($boxes.find('.side'));
  
  mainTl
    .to($randomSides, 0.5, {opacity: 0})

  mainTl.play();
};

//destroy timeline
module.exports.cleanup = function() {

}