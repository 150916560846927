var $            = require('jquery');
var jQuery       = window.jQuery =$;
require('./lib/modernizr');

window.Roma = {
  'initialRender': true,
  'contentLoading': true,
  
  'isHandheld': null,
  'isSmartphone': null,
  'isTablet': null,
  'isDesktop': null,

  'windowWidth': $(window).width(),
  'windowHeight': $(window).height(),

  'preventScrolling': false,
  'preventNavigation': false,
  'cachedPaths': {},
  'cachedVideoBgs': {},
  'buttonTouched': false
};

//jQuery plugin for binding to event only once
$.fn.once = function(a, b) {
  return this.each(function() {
    $(this).off(a).on(a,b);
  });
};

var setFlagClasses = function(){
  var  $html = $('html');
  if(window.Roma.isHandheld){
    $html.addClass('isHandheld');

  } else{
    $html.removeClass('isHandheld');
  }

  if(window.Roma.isSmartphone){
    $html.addClass('isSmartphone');
  }else{
    $html.removeClass('isSmartphone');
  }

  if(window.Roma.isTablet){
    $html.addClass('isTablet');
  }else{
    $html.removeClass('isTablet');
  }

  if(window.Roma.isDesktop){
    $html.addClass('isDesktop');
  }else{
    $html.removeClass('isDesktop');
  }
}

var notifySizeChange = function(){
  if( window.Roma.isSmartphone ){
    $(document).trigger('isSmartphone');
  } 
  
  if( window.Roma.isHandheld ){
    $(document).trigger('isHandheld');
  }

  if( window.Roma.isTablet ){
     $(document).trigger('isTablet');
  }

  if( window.Roma.isDesktop ){
     $(document).trigger('isDesktop');
  }
}

var checkDeviceType = function(){
  if (window.Roma.windowWidth >= 1025) {
    window.Roma.isHandheld = window.Roma.isSmartphone = window.Roma.isTablet = false;
    window.Roma.isDesktop = true;
  } else if (window.Roma.windowWidth >= 768) {
    window.Roma.isHandheld =  window.Roma.isTablet = true;
    window.Roma.isDesktop =  window.Roma.isSmartphone = false;
  } else {
    window.Roma.isHandheld =  window.Roma.isSmartphone = true;
    window.Roma.isDesktop =  window.Roma.isTablet = false;
  }

  setFlagClasses();
  notifySizeChange();
}

checkDeviceType();

var checkWindowSize = function(args) {
  var e = window, a = 'inner';
  if (!('innerWidth' in window )) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  window.Roma.windowWidth = $(window).width();
  window.Roma.windowHeight = e[ a+'Height' ];
}

$(window).on('resize orientationchange', function(e) {
  checkWindowSize();
  checkDeviceType();

  /*globalResize();
  scrollViewportResize();
  if (!simplifiedVersion())
    navResizeCanvas();
*/
  $(window).trigger('scroll');
});


$(function(){
  var timelineBlocks = $('.cd-timeline-block'),
  offset = 0.8;

  //hide timeline blocks which are outside the viewport
  hideBlocks(timelineBlocks, offset);

  //on scolling, show/animate timeline blocks when enter the viewport
  $(window).on('scroll', function(){
    (!window.requestAnimationFrame) 
      ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
      : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
  });

  function hideBlocks(blocks, offset) {
    blocks.each(function(){
      ( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.cd-timeline-content').addClass('is-hidden');
    });
  }

  function showBlocks(blocks, offset) {
    blocks.each(function(){
      ( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && $(this).find('.cd-timeline-content').hasClass('is-hidden') ) && $(this).find('.cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
    });
  }
    
  //Mobile menu
  $('.mob-menu-icon').click(function(){
    //$('.nav-menu').toggleClass('nav-menu-open');
    $('html').toggleClass('nav-menu-open');
    $('.mob-menu-mask').toggleClass('mask-show');
    $('body').toggleClass('no-overflow');
  });
  
  var $they_have_children = $('.has-children');
  $they_have_children.append('<div class="sub-menu-cross"></div>');
  $they_have_children.find('.sub-menu-cross').click(function(){
    //console.log('clicked');
    $(this).toggleClass('sub-menu-cross-rotated').prev().slideToggle()
  });
  //$they_have_children.children('a').click(function(e){
    //  e.preventDefault();
     // $(this).siblings('.sub-menu-cross').click();
  //});
  
  
  $('button').each(function(){
    var anchor = $(this);
    var open = false;
    anchor.on('click', function(event){
      event.preventDefault();
      if(!open){
        anchor.addClass('close');
        open = true;
      } else {
        anchor.removeClass('close');
        open = false;
      }
    });
  });
});



var router = require('./lib/router');
router.start();