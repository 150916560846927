var $               = require('jquery');
var _               = require('lodash');
var page            = require('page');
var Flick           = require('fastclick');
var when            = require('when');
var transition      = require('./transition');
var preloader       = require('./preloader');
var smoothScroll    = require('smooth-scroll');
var formatDataForGF = require('./format-data-for-fg');
var vuFix           = require('viewport-units-buggyfill');
var createjs        = require('preload-js');
var helpers         = require('./helpers');
var innerHeight     = require('ios-inner-height');



//var loadAssets      = require('./load-assets');

//var scrollMonitor = require('scrollmonitor');

require('jquery.waitforimages');
//require('vide');
require('jquery-background-video');
require('jquery-match-height');

if( !('JSON' in window) ){
  var JSON = require("json3");
}else{
  var JSON = window.JSON;
}



//keys are slugs and values are parents
/*var routes = {
  'about' : 'about',
  'firm-history' : 'about',
  'strategy' : 'about',
  'social-responsibility' : 'about',
  'investors' : 'investors',
  'investment-strategy': 'investors',
  'investment-structure': 'investors',
  'investor-subscription': 'investors',
  'investor-news': 'investors',
  'investor-registration': 'investors',
};*/

/*var menu = [
  {
    'slug': 'about',
    'label': 'About',
    'children': [
      {'slug': 'firm-history', 'label': 'Firm History' },
      {'slug': 'strategy', 'label': 'Strategy'},
      {'slug': 'team', 'label': 'Team'},
      {'slug': 'social-responsibility', 'label': 'Social Responsibility'},
    ]

  },
  {'slug': 'projects', 'label': 'Portfolio'},
  {'slug': 'market-research', 'label': 'Market Research'},
  {'slug': 'investors', 'label': 'Investors'},
  {
    'slug': 'investors', 
    'label': 'Investors',
    'children' : [
      {'slug': 'investment-strategy', 'label': 'Strategy'},
      {'slug': 'investment-structure', 'label': 'Investment Structure'},
      {'slug': 'investor-subscription', 'label': 'Investor Subscription'},
      {'slug': 'investor-news', 'label': 'Investor Events'},
      {'slug': 'investor-registration', 'label': 'Register'},
    ]
  },
  {'slug': 'contact', 'label': 'Contact'}
];*/

var buildRoutes = function(menuItems, parent) {
  parent = parent || "";
  var routes = {};
  $.each(menuItems, function(){
    var $this = this;
    routes[$this.slug] = {'parent': parent, 'label': $this.label, 'hasChildren': false};
    if('children' in $this){
      routes[$this.slug]['hasChildren'] = true;
      routes = _.merge(routes, buildRoutes($this.children, $this.slug));
    }
  });
  return routes;
}

var getParentBySlug = function(slug) {
  var routes = buildRoutes(window.locals.menu);
  //console.log(routes, slug);
  return (slug in routes) ? routes[slug]['parent'] : '';
}
////console.log('window.Roma', window.Roma);

//used to track previous content loading state
//if the stats is chas changed, fire appropriate event
var stillLoading = false; 

//check state every 50ms
var checkContentLoading = function(){
  ////console.log("checkContentLoading", window.Roma.contentLoading);
  if(window.Roma.contentLoading ==! stillLoading){
    if(window.Roma.contentLoading){
      $(document).trigger('contentLoading');  
    }else{
      $(document).trigger('contentLoaded');  
    }
    stillLoading = window.Roma.contentLoading;
  }
}

var tick = function(){
  checkContentLoading();  
  setTimeout(tick, 50);
};

var destroyBgVideos = function() {
  var $bgVideo = $('.jquery-background-video');
  $bgVideo.each(function(){
    var $this = $(this);
    $(this).get(0).pause();
    $this.find('source').remove();
  });
}



var bindGlobalEvents = (function() {
  //console.log("bound events");
  
  $(document).on('contentLoaded', function() {
    //console.log("content Loaded ");
    //$('html').removeClass('contentLoading');
    //transition.in();
  });

  $(document).on('contentLoading', function() {
    //console.log("content Loading...");
    //$('html').addClass('contentLoading');
    //transition.out();
  });

  /*var elementWatcher = scrollMonitor.create( $('nav.nav-main'), {top: 1} );

  elementWatcher.enterViewport(function() {
    //console.log( 'I have entered the viewport' );
    $('html').removeClass('scrolling');
  });
  elementWatcher.exitViewport(function() {
    $('html').addClass('scrolling');
    //console.log( 'I have left the viewport' );
  });
*/
 
});


/**
 * splits path into segments
 * @param  string path 
 * @return array      collection of path segments
 */
var getPathSegments = function(path)  {
  var DS = (path.match(/\//ig)) ? '/' : '\\';
  var segments = path.split(DS);

  return segments;
}

/**
 * obtains a template object
 * @param  [string] path route path
 * @return [function] a function when executed returns HTML 
 */
var getTemplate = function(path)  {
  path      = cleanPath(path);
  var d     = when.defer();
  var route = '';


  //can we find page in the templates var?
  if( path + '/index' in window.templates ) {
    route = path + '/index';
  } else if( path + '\index' in window.templates ) {
    route = path + '\index';
  } else if( path in window.templates ) {
    route = path;
  }

  //we are dealing with a page that is available in client templates
  if( route > '' ){
    var template = templates[route];
    
    if( typeof(template) === 'undefined' ){
      d.reject('template not found');
    } else {
      d.resolve(template);
    }
  //we are dealing with a child tempalte that needs to be ajaxed in
  } else if(getPathSegments(path).length > 1) {
    //we are dealing with either projects or team memebrs
    //so need to do an ajax fetch 
    
    var url = '/' + path;
    var promise = when($.get(url)); //converting jquery promise to a when promise
    //cache the ajaxed template
    promise
      .then(function(data)  {
        templates[path] = data;
      })
      .catch(function(){
        d.reject('template not found');
      });

    return promise;
  }else{
    d.reject('template not found');
  }

  /* if (!(route in window.templates) && getPathSegments(path).length > 1) {
    //console.log('we are dealing with either projects or team memebrs', getPathSegments(path));
    //we are dealing with either projects or team memebrs
    //so need to do an ajax fetch 
    
    //converting jquery promise to a when promise
    path = `/${path}`;
    var promise = when($.get(path));
    return promise;
  }else{
    var template = templates[route];
    
    if( typeof(template) === 'undefined' ){
      d.reject('template not found');
      page('404');
    } else {
      d.resolve(template);
    }
  }*/

  return d.promise;
}

var cleanPath = function(path){
  var route = path.replace(/^\/|\/$/g, '');
  return route || 'home';
}

var getPageSlug = function(path) {
  //path = path.replace(/^\//, '');
  path = cleanPath(path);
  return path;
}

var updateNavigation = function(slug){
  var routes = buildRoutes(window.locals.menu);
  var parent = getParentBySlug(slug);
  $('.main-nav li a, .sub-menu li a').each(function(){
    var $this = $(this);
    var $li = $this.parent('li');
    
    $li.removeClass('active');

    if(slug == $this.attr('data-slug') || parent == $this.attr('data-slug') ){
      $li.addClass('active');      
    }

  })
  
}

var cleanTmpPageContainer = function(){
  var $tmpPageContainer = $('.tmp-page-container');
  $tmpPageContainer.empty();
}

/**
 * builds new page title
 * @param String pageTitle page title that gets appended to the default page title
 */
var setMetaTitle = function(pageTitle){
  var completePageTitle = ( pageTitle > '' ) ? window.locals.defaultPageTitle + ' - ' + pageTitle : window.locals.defaultPageTitle;
  //console.log(pageTitle)
  $('title').text(completePageTitle);
}

/**
 * add new meta description
 * @param String pageTitle page title that gets appended to the default page title
 */
var setMetaDescription = function(pageDescription){
  var completePageDescription = ( pageDescription > '' ) ? pageDescription :  window.locals.defaultPageDescription ;
  $('meta[name="description"]').attr('content', completePageDescription);
}

/**
 * swaps content in page container for the new one
 * @param  {[type]} ctx [description]
 * @return {[type]}     [description]
 */
var render = function(ctx){
  /*console.log(ctx)
  setTitle();*/
  updateNavigation(getPageSlug(ctx.path));
  var $tmpPageContainer = $('.tmp-page-container');
  var $body             = $('body');
  var $pageContainer    = $('.page-container');

  $pageContainer.add($body).attr('data-slug', getPageSlug(ctx.path));
  $pageContainer.add($body).attr('data-parent', (getParentBySlug(getPageSlug(ctx.path)) || getPageSlug(ctx.path)) );
  
  var pageMetaTitle       = $tmpPageContainer.find('[data-page-meta-title]').attr('data-page-meta-title') || window.locals.defaultPageTitle;
  var pageMetaDescription = $tmpPageContainer.find('[data-page-meta-description]').attr('data-page-meta-description') || window.locals.defaultPageDescription;
  //console.log(pageMetaDescription);
  setMetaTitle(pageMetaTitle);
  setMetaDescription(pageMetaDescription);

  $pageContainer.html($tmpPageContainer.html());

  cleanTmpPageContainer();
}


var extractVideoSources = function($video){
  var sources = $video.attr('data-video-bg');
  
  if( sources > '' ){
    sources = sources.split(',');
    sources = _.reduce(sources, function(result, value, key){
      var parts = value.split(':');
      var k = parts[0].trim();
      var v = parts[1].trim();
      result[k] = v;

      return result;
    }, {});
    
    return sources;
  }

  return {};
}

/**
 * 1. find bg video element
 * 2. detect what video format to preload
 * 2. preload supported video format
 * 3. build video element
 * 
 * @param  {[type]} $html [description]
 * @return {[type]}       [description]
 */
var loadVideoBgs = function($html){
  var d                    = when.defer();
 
  $html                    = $html || $('html');

  var $video               = $html.find('.jquery-background-video').eq(0); //take the first jQuery object
  var sources              = extractVideoSources($video);
  var supportedVideoFormat = helpers.getBestSupportedVideoFormat();

  if( _.size(sources) > 0 ){
    var videosTarget = null;
    
    var queue = new createjs.LoadQueue(true);
    
    queue.on("fileload", function(event) {
      videosTarget = event.result;
    });

    queue.loadFile({
      id : supportedVideoFormat,
      //src : 'https://vjs.zencdn.net/v/oceans.mp4', 
      src: sources[supportedVideoFormat],
      type : createjs.LoadQueue.BINARY
    });

    queue.on("complete", function(){
      var $source = $('<source type="video/mp4"/>');
      var src = videosTarget;

      var blob = new Blob( [ src ], { type: "video/" + supportedVideoFormat } );
      var urlCreator = window.URL || window.webkitURL;
      var objUrl = urlCreator.createObjectURL( blob );

      $source.attr('src', objUrl);
      $video.append($source);
      $video.get(0).play();

      //give chance for video to init
      setTimeout(function(){ d.resolve(); }, 500);
      
    }, this);
  }else{
    d.resolve();
  }

  return d.promise;
}

var loadImages = function($html){
  $html = $html || $('html');
  var d = when.defer();

  $html.waitForImages({ waitForAll: true }).done(function(e){
    d.resolve();
  });

  return d.promise;
}




var loadNewContent = function(ctx)  {
  //console.log('loading new content', ctx);
  var d = when.defer();
  var $templateHtmlPromise = getTemplate(ctx.path);
  var $tmpPageContainer = $('.tmp-page-container'); //temporary container for new content, so as to be able to track loading of assets

  $templateHtmlPromise
    .then(function(template){
      //collection of promises that would need to be fulfilled before we could say that 
      //the page is ready to be rendered
      var promises = []; 

      if(typeof template === 'function'){ //we are dealing with content coming from client templates
        
        //we pass window.locals to temlate to make locals available to the template internals
        var html = template(window.locals)
        //console.log(html);
        $tmpPageContainer.html(html);
        promises.push(loadImages($tmpPageContainer));
        if(window.Roma.isDesktop){
          promises.push(loadVideoBgs($tmpPageContainer));
        }
      } else { //we are dealing with content coming from the successful ajax request
        var html = $(template).find('.page-container').html();
        $tmpPageContainer.html(html);
        promises.push(loadImages($tmpPageContainer));
        if( window.Roma.isDesktop ){
          promises.push(loadVideoBgs($tmpPageContainer));
        }
      }

      when.all(promises)
        .then(function(){
          d.resolve();
        });
    })
    .catch(function(){
      d.reject("could not load a template");   
      page.redirect('/404');
    })

  return d.promise;
}


var setupSmoothScrolling = function() {
  /*smoothScroll.init({
    speed: 800, // Integer. How fast to complete the scroll in milliseconds
    easing: 'easeInOutCubic' // Easing pattern to use
  });*/

  $('a[data-scroll]')
    .off('click tap')
    .on('click tap', function(e){
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;
        var anchor = $(hash).get(0);
        
        smoothScroll.animateScroll(anchor, null, {speed: 1000});
      }
    });
}

var setupPage =  function(cb){
  window.scrollTo(0, 0);
  Flick(document.body); //Polyfill to remove click delays on browsers with touch UIs
  setupSmoothScrolling();

  $('footer .forth').matchHeight();
    if(typeof cb === 'function'){
    cb();
  }

  //fix ios toolbar affecting the height of viewport
  setTimeout(function(){
    $(window).scrollTop(-1);
    fixBannerHeight();
  }, 500);
  
  $(window).on('resize', function() {
    fixBannerHeight();
  });
}

var minTimeOut = function(delay) {
  delay = delay || 1000;
  var d = when.defer();
  setTimeout(function(){
    d.resolve();
  }, delay);

  return d.promise;
}

var resetFormFields = function($form){
  if( $form.length > 0 ){
    $form
      .find('input[type=text], input[type=email], textarea')
        .val("")
        .removeClass('invalid')
      .next('.error')
        .removeClass('active')
  }
}

$.fn.showMessage = function(txt, type) {
  type = type || "success";
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .attr('data-type', type)
      .text(txt)
      .slideDown('fast')
      .delay(20000)
      .slideUp('fast')
      .attr('data-type', '');
  }
}

$.fn.hideMessage = function() {
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .slideUp('fast');
  }
}

var showMessage = function(txt, type) {
  type = type || "success";
  var $message = $('.form-message');
  $message
    .attr('data-type', type)
    .text(txt)
    .slideDown('fast')
    .delay(20000)
    .slideUp('fast')
    .attr('data-type', '');
}


var handleFormErrors = function($form, data) {
  
  for(var key in data.response.validation_messages){
    var $this = $form;
    //console.log(key, data.response.validation_messages[key]);
    if(data.response.validation_messages.hasOwnProperty(key)){

      $this
        .find('[name=input_' + key + ']')
          .addClass('invalid')
        .next('.error')
          .text(data.response.validation_messages[key])
          .addClass('active')
    }
  }
}

var setupNewsletterSignupForm = function() {
  var $form = $('#mc-embedded-subscribe-form');
  $form
    .find('input[type=text], input[type=email], textarea')
    .on('keypress', function(){
      $(this)
        .removeClass('invalid')
        .next('.error')
          .removeClass('active');
    });

  $form.on('submit', function(e){
    e.preventDefault();
    $form.hideMessage();
    var $this = $(this);
    var data = formatDataForGF($this.serializeArray());
   
    $.ajax({
      type: $this.attr('method'),
      url: $this.attr('action'),
      data: JSON.stringify(data),
      dataType: 'json'
    }).done(function(data){
      //check for errors and show related message
      //console.log(data);

      if( data.response.is_valid ){
        $form.showMessage("Thank you for subscribing to our newsletter", 'success');
        resetFormFields($form);
      }else{
        handleFormErrors($form, data);
      }

    })
    .fail( function(jqXHR, textStatus, errorThrown){
      $form.showMessage('We could not process your subscription, please try again', 'fail');
    });
  });
}

var loopButtonAnimation = function($a, delay) {
  if( !window.Roma.buttonTouched ){
    $a.addClass('hover');
    setTimeout(function(){
      $a.removeClass('hover');
      setTimeout(function(){
        loopButtonAnimation($a, delay);
      }, 100);
      
    }, delay);
  }
}


var setupAnimations = function() {
  window.Roma.buttonTouched = false;
  //animate roma button every N seconds until first interaction
  var $a = $('.btn-roma-looped');
  var delay = 2000;
  
  $a.on('mouseenter click tap', function(){
    window.Roma.buttonTouched = true;
  });

  $(window).on('scroll swipe', function(e){
    window.Roma.buttonTouched = true;
    $(window).off('scroll swipe');
  });

  loopButtonAnimation($a, delay);
}

var initVideoBgs = function(){
  //play video bg
}


var fixBannerHeight = function(){
  var ih = innerHeight();
  //console.log("innerHeight:", ih, window.innerHeight);
  //console.log($('.page-banner.fullscreen'));
  $('.page-banner.fullscreen').css('height', ih + 'px');
}

module.exports = function(ctx, next) {
  var $html = $('html');
  //smoothScroll.init();
  ctx.handled = true; //needed to prevent server roundtrip

  var initRender = ctx.init;
  ////console.log("before loadingNewContent");
  
  


  if(!initRender){
    //console.log(ctx);
   /* if(window.location.pathname == ctx.pathname){
      return false;
    }*/
    window.Roma.contentLoading = true;
    transition.out(); //start transition loop

    //the least we will wait is until minTimeOut resolves
    var promises = [minTimeOut(), loadNewContent(ctx)];
    when.all(promises)
      .then(function(values){
        vuFix.init(); //shim for vw, vh
        tick();
        render(ctx);
        window.Roma.contentLoading = false; //done loading content
        //initVideoBgs();
        $html.removeClass('nav-menu-open');
        $('body').removeClass('no-overflow');
        setupAnimations();
        setupPage(next);
      });
  }else{
    //initial page load (page is server built)
    
    bindGlobalEvents();
    setupNewsletterSignupForm();
  
    var promises = [ 
      preloader.start(), //preloader needs to complete animating before we could allow it to be taken down
      minTimeOut(),
      loadImages()
    ];

    if(window.Roma.isDesktop){
      promises.push(loadVideoBgs());
    }

    when.all(promises)
      .then(function(){
        //initVideoBgs();
        
        preloader.end(function(){
          var $html = $('html');
          window.Roma.contentLoading = false;
          $html.removeClass('initialLoad');
          $html.removeClass('nav-menu-open');
        }); //preloader exit ing animation
        vuFix.init();
        setupAnimations();
        setupPage(next);
      });   
  }

}