var $                = require('jquery');
var jQuery           = window.jQuery = $;
var Isotope          = require('isotope-layout');
var GoogleMapsLoader = require('google-maps');
var formatDataForGF  = require('../lib/format-data-for-fg');

if( !('JSON' in window) ){
  var JSON = require("json3");
}else{
  var JSON = window.JSON;
}

require('bootstrap');

var resetFormFields = function($form) {
  if( $form.length > 0 ){
    $form
      .find('input[type=text], input[type=email], textarea')
        .val("")
        .removeClass('invalid')
      .next('.error')
        .removeClass('active')
  }
}
$.fn.showMessage = function(txt, type) {
  type = type || 'success';
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .attr('data-type', type)
      .text(txt)
      .slideDown('fast')
      .delay(20000)
      .slideUp('fast')
      .attr('data-type', '');
  }
}

$.fn.hideMessage = function() {
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .slideUp('fast');
  }
}

var showMessage = function(txt, type){
  type = type || 'success';
  var $message = $('.form-message');
  $message
    .attr('data-type', type)
    .text(txt)
    .slideDown('fast')
    .delay(20000)
    .slideUp('fast')
    .attr('data-type', '');
}


var handleFormErrors = function($form, data){
  
  for(var key in data.response.validation_messages){
    var $this = $form;
    //console.log(key, data.response.validation_messages[key]);
    if(data.response.validation_messages.hasOwnProperty(key)){

      $this
        .find('[name=input_' + key + ']')
          .addClass('invalid')
        .next('.error')
          .text(data.response.validation_messages[key])
          .addClass('active')
    }
  }
}

var initMap = function(){
  var mapStyle = [
    {
      elementType: 'geometry',
      stylers: [{color: '#f5f5f5'}]
    },
    {
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{color: '#616161'}]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{color: '#f5f5f5'}]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{color: '#bdbdbd'}]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{color: '#eeeeee'}]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{color: '#757575'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{color: '#e5e5e5'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{color: '#ffffff'}]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{color: '#757575'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#dadada'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{color: '#616161'}]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{color: '#e5e5e5'}]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{color: '#eeeeee'}]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{color: '#c9c9c9'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}]
    }
  ];
  GoogleMapsLoader.KEY = 'AIzaSyAFXP3aaC1OnMSHH2W1S0jhMnjr6qFxWcM';
  GoogleMapsLoader.load(function(google) {
    var romaLatLang = {lat: 51.0737606, lng: 1.1443604};
    
    var map = new google.maps.Map(document.getElementById('map'), {
      center: romaLatLang,
      zoom: 17,
      mapTypeControl: false,
      scrollwheel: false
    });

    map.setOptions({styles: mapStyle});

    var image = {
      url: '/img/roma-stamp.png',
      size: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 50),
      scaledSize: new google.maps.Size(50, 50)
    };

    // Create a marker and set its position.
    var marker = new google.maps.Marker({
      map: map,
      position: romaLatLang,
      title: 'Roma Capital',
      icon: image,
      optimized: false //the edge of scaled down image marker will appear jagged
    });
  });
}

var setupContactForm = function(){
   var $contactForm = $('#contact');
  
  //when users start typing in an invalid field, hide error message from below it
  $contactForm
    .find('input[type=text], input[type=email], textarea')
    .on('keypress', function(){
      $(this)
        .removeClass('invalid')
        .next('.error')
          .removeClass('active');
    });

  $contactForm.on('submit', function(e){
    e.preventDefault();
    $contactForm.hideMessage();
    
    var $this = $(this);
    var data = formatDataForGF($this.serializeArray());
   
    $.ajax({
      type: $this.attr('method'),
      url: $this.attr('action'),
      data: JSON.stringify(data),
      dataType: 'json'
    }).done(function(data){
      //check for errors and show related message
      //console.log(data);

      if( data.response.is_valid ){
        $contactForm.showMessage("Thank you for your enquiry. We will get back to you as soon as possible.", 'success');
        resetFormFields($contactForm);
      }else{
        handleFormErrors($contactForm, data);
      }

    })
    .fail( function(jqXHR, textStatus, errorThrown){
      $contactForm.showMessage('We could not deliver your message, please try again later', 'fail');
    });
  });
}

module.exports = function(ctx, next){
  //$.support.cors = true;
  initMap();
  setupContactForm();
};

