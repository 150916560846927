var page        = require('page');
var init        = require('./init');
var controllers = require('./controllers');
var transition  = require('./transition');
var helpers     = require('./helpers');

module.exports.start = function(){
  page('*', init ); 
  page('/', controllers.home);
  page('/market-research', controllers.marketResearch);
  page('/team', controllers.team);
  page('/projects', controllers.portfolio);
  page('/projects/:project', controllers.portfolio);
  page('/contact', controllers.contact);
  page('/investor-registration', controllers.investorRegistration);
  
  //on switching from one page to the other
  page.exit('*', helpers.killEvents);
  page.start();
}