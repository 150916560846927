var $                = require('jquery');
var jQuery           = window.jQuery = $;
var Isotope          = require('isotope-layout');
var GoogleMapsLoader = require('google-maps');
var formatDataForGF  = require('../lib/format-data-for-fg');

if( !('JSON' in window) ){
  var JSON = require("json3");
}else{
  var JSON = window.JSON;
}

require('bootstrap');

$.fn.resetFormFields = function() {
  if(this.is('form')){
    var $form = this;
    $form
      .find('input[type=text], input[type=email], textarea')
        .val("")
        .removeClass('invalid')
        .end()
      .find('input[type=checkbox]')
        .prop('checked', false)
        .end()
      .end()
      .find('.error')
        .remove();
  }
}

$.fn.showMessage = function(txt, type) {
  type = type || "success";
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .attr('data-type', type)
      .text(txt)
      .slideDown('fast')
      .delay(20000)
      .slideUp('fast')
      .attr('data-type', '');
  }
}

$.fn.hideMessage = function() {
  if(this.is('form')){
    var $form = this;
    var $message = $form.find('.form-message');
    $message
      .slideUp('fast');
  }
}

$.fn.handleFormErrors = function(data) {
  if(this.is('form')){
    var $form = this;
    var form_id = Number($form.attr('id').split("_").pop());
    for(var key in data.response.validation_messages){
      //console.log(key, data.response.validation_messages[key]);
      if(data.response.validation_messages.hasOwnProperty(key)){
        var $input = $form.find('[name=input_' + key + ']');
        
        $input = ($input.length > 0) ? $input : $form.find("#field_" + form_id + "_" + key);

        var $error = $('<div class="error"></div>').text(data.response.validation_messages[key]).addClass('active');
        
       /* console.log($input);*/

        $input
          .addClass('invalid');

        if($input.is('.gfield')){
          $input = $input.find('.ginput_container');
        } 

        $input
          .siblings('.error')
            .remove()
            .end()
          .after($error);
      }
    }
  }
}

var setupForms = function()  {
  var $forms = $('.ge-form form');
  
  $forms.each(function(){
    var $form = $(this);
    //when users start typing in an invalid field, hide error message from below it
    $form
      .find('input[type=text], input[type=email], textarea, input[type=checkbox]')
      .on('keypress change', function(){
        $(this)
          .removeClass('invalid')
          .closest('.gfield')
            .removeClass('invalid')
            .find('.error')
              .remove()
      });
    $form
      .find('input:text, textarea')
      .each(function(){
        var $this = $(this);
        var $label = $this.parent('.ginput_container').siblings('label.gfield_label')

        $this.attr('placeholder', $label.contents().get(0).nodeValue);
        $label.remove();
      })
      

    $form.on('submit', function(e){
      e.preventDefault();
      
      var $this = $(this);
      var data = formatDataForGF($this.serializeArray());
     
      $.ajax({
        type: $this.attr('method'),
        url: $this.attr('action'),
        data: JSON.stringify(data),
        dataType: 'json'
      }).done(function(data){
        //check for errors and show related message

        if( data.response.is_valid ){
          $form.showMessage($(data.response.confirmation_message).text(), 'success');
          $form.resetFormFields();
        }else{
          $form.handleFormErrors(data);
        }

      })
      .fail( function(jqXHR, textStatus, errorThrown){
        $form.showMessage('We could not deliver your message, please try again later', 'fail');
      });
    });
  });
  
}

module.exports = function(ctx, next)  {
  //$.support.cors = true;
  setupForms();
};

