var _            = require('lodash');

/**
 * format data in the format that GF expects
 * @param  array data  array with serialised form data
 * @return array formatted data
 */

var formatDataForGF = function(data)  {
  data = _.reduce(data, function(result, value, key) {
    result[value.name] = value.value;
    return result;
  }, {});

  return {"input_values": data}; 
}

module.exports = formatDataForGF;