var $            = require('jquery');
var jQuery       = window.jQuery =$;
//var Util       = require("../Util");
var mousewheel   = require('jquery-mousewheel');
var gsap         = require('gsap');
var BezierEasing = require('bezier-easing');
var SplitText    = require('../greensock-js-shockingly-green/src/commonjs-flat/SplitText')
var TweenMax     = gsap.TweenMax;
var TimelineLite = gsap.TimelineLite;
var TimelineMax  = gsap.TimelineMax;

require('jquery-touchswipe');

mousewheel($); 

$.fn.reverse = [].reverse;

/*var isHandheld = null;
var isTablet = null;
var isSmartphone = null;
var isDesktop = null;  

var windowWidth = 0;
var windowHeight = 0;
var preventNavigation = false; //prevents navigating to another area while transitioning to another area
var preventScrolling = false; //do not allow scrolling to the next section if curent one is animating
var eventsCache = [];*/


var animateContent = function(next, delay){
  delay = delay || 0;
  (function(next){
    //console.log("animateContent called");
    var stateLabel = '';
    var prevStateLabel = '';
    var $activeSection = getActiveSection(),
        $animContainer = $activeSection.find('.anim-container');

    var animationState = $animContainer.attr('data-animation-state'),
        animationStateLength = $animContainer.attr('data-animation-state-length');

    //var $allAnimatable = $animContainer.find('.animatable');
    //var $title_easing = BezierEasing(0.25, 0.1, 0.0, 1.0);
    
    //choose forward animation timeline for either a mobile or a desktop
    if(window.Roma.isHandheld){
      var tlFwd = $activeSection.data('contentTimelineForwardM');  
    }else{
      var tlFwd = $activeSection.data('contentTimelineForward');
    }

    //choose reverse animation timeline for either a mobile or a desktop
    if(window.Roma.isHandheld){
      var tlRev = $activeSection.data('contentTimelineReverseM');
    }else{
      var tlRev = $activeSection.data('contentTimelineReverse');
    }

    //console.log("next:" , next);
    
    if(next){
      prevStateLabel = 'state' + animationState;
      ++animationState;  
      stateLabel = 'state' + animationState;
      /*console.log('FWD: ', prevStateLabel, stateLabel);*/
      $animContainer.attr('data-animation-state', animationState);
      //console.log("next", prevStateLabel, stateLabel);
      tlFwd.tweenFromTo(prevStateLabel, stateLabel, {delay: delay});
    }else{
      prevStateLabel = 'state' + (animationState );
      --animationState; 
      stateLabel = 'state' + (animationState );
      $animContainer.attr('data-animation-state', animationState);
      //console.log("previous", prevStateLabel, stateLabel);
      /*console.log('REV: ', prevStateLabel, stateLabel);*/
      tlRev.tweenFromTo(prevStateLabel, stateLabel, {delay: delay});  
    }

  }).bind(this)(next);
}

/*var isReadyToSwitchSection = (next) => {
  var activeSection = getActiveSection(),
      animContainer = activeSection.find('.anim-container');

  var animationState = animContainer.attr('data-animation-state'),
      animationSectionSwitchState = '';

  if(next){
    animationSectionSwitchState = animContainer.attr('data-section-switch-state-fwd')
  }else{
    animationSectionSwitchState = animContainer.attr('data-section-switch-state-rev')
  }

  //console.log(animationState, animationSectionSwitchState);

  if(next){
    return animationState >= animationSectionSwitchState;
  }else{
    return animationState <= animationSectionSwitchState;
  }

}
*/

var isReadyToSwitchSection = function(next)  {
  var $activeSection = getActiveSection();

  if(next){
    return !$activeSection.is(':last-child');
  }else{
    return !$activeSection.is(':first-child');
  }
}

var isContentInLastFrame =function ()  {
  var activeSection = getActiveSection(),
      animContainer = getActiveSection().find('.anim-container');

  var animationState = animContainer.attr('data-animation-state'),
      animationStateLength = animContainer.attr('data-animation-state-length');

  return animationState >= animationStateLength;
}

var isContentFirstFrame = function ()  {
  var activeSection = getActiveSection(),
      animContainer = getActiveSection().find('.anim-container');

  var animationState = animContainer.attr('data-animation-state'),
      animationStateLength = animContainer.attr('data-animation-state-length');

  return animationState <= 1;
}


var updateSectionMonitor = function(nextSection)  {
  var newIndex = nextSection.index() + 1;
  $('.home-section-monitor').attr('data-active-section', newIndex);
  $(document).trigger('activeSectionChanged', [newIndex]);
}

var getSection = function(index) {
  return $('.scroll-section-wrapper .scroll-section[data-section=' + index + ']' );
}

var getActiveSection = function() {
  //convert to 0 based index for $.eq()
  var index = getActiveSectionIndex();
  ////console.log($('.scroll-section-wrapper .scroll-section').eq( index ));
  return getSection(index);
}

var getActiveSectionIndex = function() {
  //convert to 0 based index for $.eq()
  var index = $('.home-section-monitor').attr('data-active-section');
  ////console.log($('.scroll-section-wrapper .scroll-section').eq( index ));
  return index;
}
/**
 * if next is true, we find the element following the current active section 
 * if next is false, we find the element preceding the current active section 
 * and call animation controller that transitions from section to section 
 * @param  bool next true => next section, false => previous section
 */
var homeNextSection = function(next) {
  // Selectors
  //var layout = $('.layout-works').first();
  //var activeSection = $('.scroll-section-wrapper .scroll-section.is-active');
  var activeSection = getActiveSection();
  
  if (next){
    var nextSection = activeSection.next('.scroll-section');
   } else{
    var nextSection = activeSection.prev('.scroll-section');
  }
 
  homeSwitchSection(nextSection);
}


var homeNextSubSection = function(next) {

  if(!window.Roma.preventScrolling){
    window.Roma.preventScrolling = true;
    setTimeout(function(){
      window.Roma.preventScrolling = false;
    }, 2000);
  }else{
    return false; //prevent quick between section navigation
  }

  var $subsections      = getSubSections();
  var $activeSubSection = getActiveSubSection();
  
  if (next){
    var $nextSubSection = $subsections.eq($subsections.index($activeSubSection)+1);
   } else{
    var $nextSubSection = $subsections.eq($subsections.index($activeSubSection)-1);
  }

  $nextSubSection = ($nextSubSection.length > 0) ? $nextSubSection : $activeSubSection;
 
  homeSwitchSubSection($nextSubSection);
}

var homeSwitchSubSection = function($nextSubSection) {
  var $subsections = getSubSections();
  var $activeSubSection = getActiveSubSection();
  
  if(typeof $nextSubSection === 'undefined'){
    $nextSubSection = $activeSubSection;
  }

  var startOut = 0;
  var startIn  = 0.4;
  
  var tl = new TimelineMax({paused: true});
  
  if($subsections.index($nextSubSection) > $subsections.index($activeSubSection)){
    var down = true
  } else {
    var down = false;
  }

  //hiding current active subsection
  $activeSubSection.each(function(){
    var $this = $(this);

    if (down) {
      var toY = -30; //animating out in upward direction
    } else {
      var toY = 30; //animating out in downward direction
    }

    tl.to($activeSubSection, 0.35, {opacity: 0, y: toY, ease: Power3.easeOut}, startOut);
    tl.set($this.closest('.scroll-section'), {opacity: 0});
    tl.call(function(){
      updateSectionMonitor($nextSubSection.closest('.scroll-section'));
    }, null, null, startOut+0.15);
    tl.call(function(){
      $this.removeAttr('data-active');
    });

    if($subsections.index($activeSubSection) > 0){
      tl.set($activeSubSection, {display: 'none'});
    }
  });

  //revealing next subsection
  $nextSubSection.each(function(){
    var $this = $(this);

    if (down) {
      var fromY = 30; 
    } else {
      var fromY = -30; 
    }    

    TweenMax.set($this, { opacity: 0, y: fromY, display: 'block' } );
    tl.set($this.closest('.scroll-section'), {opacity: 1}, startIn);
    tl.to($this, 0.35, {opacity: 1, y: 0, ease: Power3.easeOut}, startIn);
    tl.call(function(){
      $this.attr('data-active', 'true');
    });
  });

  tl.play();

}

var getSubSections = function() {
  return $('.scroll-section-wrapper .animatable');
}

var getActiveSubSection = function(){
  var $subsections = getSubSections();
  var $activeAnimatable = $subsections.filter('[data-active]');
  return ($activeAnimatable.length > 0) ? $activeAnimatable : $subsections.eq(0);
  
}
/**
 * Desktop specific section animation
 */
var homeSwitchSectionDesktop = function($nextSection){
  var initial        = false;
  var $activeSection = getActiveSection();
  
  var startOut       = 0;
  var startIn        = 0.8;

  var tl             = new TimelineMax({paused: true});
  var $title_easing  = BezierEasing(0.25, 0.1, 0.0, 1.0);

  if (typeof $nextSection === 'undefined'){
    initial      = true;
    $nextSection = $activeSection;
    startIn      = 0;
  }

  //exiting sequence for current active section
  if(!initial){
    $activeSection.each(function(){
      var $this = $(this);

      var $animContainer = $this.find('.anim-container');
      var $title         = $animContainer.find('h1'); 
      var $blurb         = $animContainer.find('.blurb');
      var $hr            = $animContainer.find('hr'); 
      var $discoverMore  = $animContainer.find('.discover-more');
      var $paragraphs    = $blurb.find('p'); 
       
      var $splitThese    = $animContainer.find('p');
      var splitText      = new SplitText($splitThese, {type: 'lines', linesClass: 'split-line'} );
      
      var contentElements = $animContainer.find('h1, .split-line, hr, .btn-roma, .discover-more'); //stagger these elements

      //console.log(splitText);

      if ($nextSection.index() > $activeSection.index()) {
        var toY = -30;
      } else {
        var toY = 30;
        contentElements.reverse();
      }

      //exiting animation of current section
     
      tl.staggerTo(contentElements, 0.35, {opacity: 0, y: toY, ease: Power3.easeOut}, 0.01, startOut);
      tl.call(function(){
        //this sets current section index on the section container,
        //this takes care of switching number breadcrumb (using css)
        //this takes care of switching section backgrounds (using css)
        updateSectionMonitor($nextSection);
      }, null, null, startOut+0.2);
      tl.call(function(){
        //contentElements.css({opacity: '', transform: ''});
        splitText.revert();
      }, null, null, startIn);
      tl.set($activeSection, {opacity: 0, display: 'none' });
    });
  }
  
  //entering sequence for new active section
  $nextSection.each(function(){
    var $this           = $(this);

    var $animContainer  = $this.find('.anim-container');
    var $title          = $animContainer.find('h1'); 
    var $blurb          = $animContainer.find('.blurb');
    var $hr             = $animContainer.find('hr'); 
    var $discoverMore   = $animContainer.find('.discover-more');
    var $paragraphs     = $blurb.find('p'); 

    var $splitThese = $animContainer.find('p');
    var splitText = new SplitText($splitThese, {type: 'lines', linesClass: 'split-line'} );

    var contentElements = $animContainer.find('h1, .split-line, hr, .btn-roma'); //stagger these elements
    //console.log(initial);
    if(!initial){
      contentElements = contentElements.add($discoverMore);
      //console.log(contentElements);
    }
    //console.log('$nextSection.index(): ', $nextSection.index(), '$activeSection.index(): ', $activeSection.index());
    if ($nextSection.index() >= $activeSection.index()) {
      var fromY = 30;
    } else {
      var fromY = -30;
      contentElements.reverse();
    }

    TweenMax.set(contentElements, {opacity: 0, y: fromY } );
    TweenMax.set($hr, {y: 0});
    if(initial){
      TweenMax.set($discoverMore, {opacity: 0, y: 0, x: '-20px'});
    }

    tl.set($nextSection, {opacity: 1, display: 'block' }, startIn );
    tl.staggerTo(contentElements, 0.35, {opacity: 1, y: 0, ease: new Ease($title_easing)}, 0.01, startIn);
    tl.fromTo($hr, 0.6, {width: 0, opacity: 0, ease:new Ease($title_easing)}, {width: '100%', opacity: 1, ease:new Ease($title_easing)}, startIn+0.3);
    tl.call(function(){
      //contentElements.css({opacity: '', transform: ''});
      //splitText.revert();
    })
    if( $discoverMore.length > 0 ){
      var d = (initial) ? '+=3.4' : '+=0';
      tl.to($discoverMore, 0.7, {opacity: 1, x: '0px', ease: Power2.easeOut}, d);
    }

  });

  tl.play();
}


/**
 * Animates switching of sections
 * @param $nextSection object jquery object of the section we are switching to
 */
var homeSwitchSection = function( $nextSection ){

  if(!window.Roma.preventScrolling){
    window.Roma.preventScrolling = true;
    setTimeout(function(){
      window.Roma.preventScrolling = false;
    }, 2000);
  }else{
    return false; //prevent quick between section navigation
  }

  homeSwitchSectionDesktop($nextSection);
}

/**
 * universal handler for section navigation events
 * @param  object e jq event object
 */
var homeVerticalNav = function(e){
  var down = null;
  
  if (e.type == 'mousewheel' || e.type == 'DOMMouseScroll') {
    down = (e.deltaY < 0);
  } else if (e.type == 'keyup') {
    if (e.which == 40){
      down = true;
    } else if (e.which == 38){
      down = false;
    }
  } else if (e.direction > ''  ){
    if(e.direction == 'up'){
      down = true;
    }else{
      down = false;
    }
  }

  if (down == null){
    return false;
  }

  ////console.log(this.preventNavigation);
  ///window.Roma.isHandheld
  if( window.Roma.preventNavigation ){
    return false;
  }

  if(window.Roma.isDesktop || window.Roma.isTablet){
    if (down) {
      if(isReadyToSwitchSection(true)){
        homeNextSection(true);
      }
    }else{
      if(isReadyToSwitchSection(false)){
        homeNextSection(false);
      }
    }  
  } else if(window.Roma.isSmartphone) {
    if (down) {
      if(isReadyToSwitchSubSection(true)){
        homeNextSubSection(true);
      }
    }else{
      if(isReadyToSwitchSubSection(false)){
        homeNextSubSection(false);
      }
    }
  }
}

//todo: complete this function
var isReadyToSwitchSubSection = function(down) {
  var $subsections = getSubSections();
  var $activeSubSection = getActiveSubSection();

  if(down && $subsections.filter(':last').get(0) != $activeSubSection.get(0)){
    return true;    
  }

  if(!down && $subsections.filter(':first').get(0) != $activeSubSection.get(0)){
    return true;    
  }

  return false;
}

var controlVideoBgs = function () {
  if(window.Roma.isDesktop){
    var activeSectionNumber = $('.home-section-monitor').attr('data-active-section');
    var $slides = $('.scroll-bgs .slide');
    var $currentSlide = $slides.eq(Number(activeSectionNumber)-1);
    $slides.not($currentSlide).find('video').each(function(){
      setTimeout(function(){
        this.pause(); 
        this.currentTime = 0;
      }, 1000);
      
    });

    $currentSlide.find('video').each(function(){
      this.play();
    });
  }
}

var stopBgVideos = function() {
  $('.scroll-bgs video').each(function(){
    //it is playing playing
    if(!this.paused){
      $(this).addClass('paused-for-mobile');
    }
    this.pause();
    this.currentTime = 0;
  });
}

var playStoppedBgVideos = function() {
  $('.paused-for-mobile').each(function(){
    if(this.paused){
      this.currentTime = 0;  
      this.play();
    }
    $(this).removeClass('paused-for-mobile');
  });
}

var updateMaskType = function(){
  //console.log(index);
  var args = [].slice.call(arguments);
  args = args.slice(1);
  //we are dealing with an event handler 
  var index = (args.length > 0) ? args[0] : 0;
  


  var $section = getSection(index);
  var maskType = $section.attr('data-mask');
  
  if(typeof maskType !== 'undefined'){
    var $masked = $section.closest('.masked');
    $masked.removeClass (function (index, className) {
      return (className.match (/\bmark\S+/g) || []).join(' ');
    });
    $masked.addClass('mask' + maskType);
  }
  
}

var setupEvents = function(ctx)  {

  //when not on the first section, stop video bg to preserve resources
  $(document).once('activeSectionChanged', controlVideoBgs);
  $(document).once('activeSectionChanged', function(){
    if(getActiveSectionIndex() > 1){
      window.Roma.buttonTouched = true;
    }
  });
  $(document).once('isHandheld', stopBgVideos);
  $(document).once('isDesktop', playStoppedBgVideos);

  //mouse and swipe controlls for home section navigation
  $(document).once('mousewheel DOMMouseScroll', function(e) {homeVerticalNav(e)} );
  $(document).once('keyup', function(e) {homeVerticalNav(e)});
  $('.scroll-section a[data-action=next]').once('click tap', function(e){
    e.preventDefault();
    if(window.Roma.isDesktop || window.Roma.isTablet){
      homeNextSection(true);
    }else if (window.Roma.isSmartphone){
      homeNextSubSection(true);
    }
  });

  $(document).swipe({
    swipe: function(event, direction) {
      homeVerticalNav({type: 'swipe', direction: direction});
    },
    fallbackToMouseEvents: false,
    excludedElements: '.noSwipe'
  });

  //breadcrumb navigation
  $('.controls-slide').on('click tap', 'li', function(){
    var $this = $(this);
    var index = $this.attr('data-section');

    homeSwitchSection(getSection(index));
  });
  $('.btn-roma-first').on('mouseenter', function(){
    window.Roma.buttonTouched = true;
  });
}

var firstButtonAnimate = function(){
  var $btn = $('.btn-roma-first');
  $btn.addClass('btn-roma-load');

  setTimeout(function(){
    $btn.removeClass('btn-roma-load');
  }, 1000);

  setTimeout(function(){
    if(!window.Roma.buttonTouched){
      firstButtonAnimate();
    }
  }, 2000);
}

var setupFirstButtonAnimation = function() {
  firstButtonAnimate();
}

var animateDiscoverMore = function(){
  setTimeout(function(){
    $('.discover-more').addClass('enter');
  }, 4500);
}

module.exports = function(ctx, next){ 
  window.Roma.buttonTouched = false;
  setupEvents(ctx);
  controlVideoBgs();
  animateDiscoverMore();
  //setupFirstButtonAnimation();
  //setupAnimations();
  //setupHomeSliderAnimation();
  //animateContent(true); //jump start animation by triggering 1st animation state of the 1st section
  
  //homeSwitchSection();
  if( window.Roma.isDesktop || window.Roma.isTablet){
    
    homeSwitchSection();
  }else if(window.Roma.isSmartphone){
    homeSwitchSubSection();
  }
};
