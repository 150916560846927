var $            = require('jquery');
var jQuery       = window.jQuery =$;
var Isotope      = require('isotope-layout');

require('jquery-match-height');

module.exports = function(ctx, next) { 
  $('.grid-item').matchHeight(); 
  /*var $filter_buttons = $('.filter-tabs-portfolio'); 
  var $grid = $('.grid');

  if($grid.get().length > 0){
    var iso = new Isotope( '.grid', {
      itemSelector: '.grid-item',
      percentPosition: false,
      layoutMode: 'masonry',
      filter: $filter_buttons.find('li').eq(0).attr('data-filter')
    });

    // filter items on button click
    $filter_buttons.on( 'click', 'li', function() {
      var filterValue = $(this).attr('data-filter');
      var currentButton = $(this);
      iso.arrange({ filter: filterValue });
      $($filter_buttons).trigger('filtered', [currentButton]);
    });

    $filter_buttons.on('filtered', function(e, currentButton){
      $(this).find('li').each(function(i){
        $(this).removeClass('active');
      });

      currentButton.addClass('active');
    });

    iso.on('layoutComplete', function(){
      $grid.addClass('grid-ready');
    });

    iso.layout();
    iso.layout();
  }*/
};
