var $            = require('jquery');
var gsap         = require("gsap");
var BezierEasing = require("bezier-easing");
var when         = require('when');
var helpers      = require('./helpers');

var TweenMax     = gsap.TweenMax;
var TimelineLite = gsap.TimelineLite;
var TimelineMax  = gsap.TimelineMax;

var $html        = $('html');

/*$.fn.randomize = function(selector){
    var $elems = selector ? $(this).find(selector) : $(this).children(),
        $parents = $elems.parent();

    $parents.each(function(){
        $(this).children(selector).sort(function(){
            return Math.round(Math.random()) - 0.5;
        // }). remove().appendTo(this); // 2014-05-24: Removed `random` but leaving for reference. See notes under 'ANOTHER EDIT'
        }).detach().appendTo(this);
    });

    return this;
};
*/

var mainTl       = null;
var $boxes       = null;
var $stamp       = null;
var $randomSides = null;

var poller = function(cb, params){
  if(window.Roma.contentLoading){
    setTimeout(function(){
      poller(cb, params);
    }, 200);
    
  }else{
    if(typeof cb == 'function'){
      return cb(params);
    }  
  }
}



module.exports.start = function(cb) {
  var d = when.defer();
  
  var title_easing = BezierEasing(0.25, 0.1, 0.0, 1.0);
  //var logo_easing = BezierEasing(.4,.04,.49,1.78);
  //var mainTl = new TimelineMax({paused: false,  delay: 1, ease:new Ease(title_easing)});
  mainTl = new TimelineMax({
    paused: false,  
    delay: 0, 
    ease:Power0.easeNone, 
    onComplete: function() { 
      //setTimeout(function() {d.resolve(); }, 250);
      setTimeout(function(){
        if(typeof cb == 'function'){
          cb();   
        }
        d.resolve();   
      }, 500);
    }  
  });

  mainTl.timeScale(window.locals.preloaderSpeed);
  //mainTl.timeScale(1);

  var boxTimelines = [];
  $stamp = $('.loading-stamp');
  //randomly select boxes so they not always animate in a linear fashion
  $boxes = helpers.shuffle($( '.pl-box' ));

  $boxes.each( function(i) {
    var $currentBox = $(this);
    var tlBox = new TimelineMax({paused: false});
    var $sides = $currentBox.find('.side');
    boxTimelines.push(tlBox);

    //add box sides to the timeline
    $sides.each(function(){
      var $side = $(this);
      tlBox
        .from( $side, 0.2, { opacity: 0.8, ease: Power0.easeNone })  
        .from( $side, 1, { scaleY: '0', scaleX: '0', ease: Power0.easeNone }, '-=0.2');
    });

    var random_overlap = Math.random() * 0.7 + 3;
    mainTl
      .add(tlBox, '-=' + random_overlap);
  });

  $randomSides = helpers.shuffle($boxes.find('.side'));

  //window.Roma.contentLoading
  mainTl
    .from( $stamp, 1.5, { opacity: 0, scale: 0.5, ease: Power2.easeOut });

  $html.addClass('preloaderReady'); //at this point preloader should be ready, so show it
  
  /*$(document).on('contentLoaded', function(){
    mainTl.play();
  });*/

  return d.promise;
}



module.exports.end = function(cb) {
  var d = when.defer();

  mainTl = new TimelineMax({
    paused: false,  
    delay: 0, 
    ease:Power0.easeNone, 
    onComplete: function() { 
      //setTimeout(function() {d.resolve(); }, 250);
      setTimeout(function(){
        if(typeof cb == 'function'){
          d.resolve();
          cb();   
        }
      }, 100);
    }  
  });

  mainTl
    .staggerTo($randomSides.add($stamp), 1, {opacity: 0}, 0.13, '+=1')
    .play();

  return d.promise;
}