var $            = require('jquery');
var jQuery       = window.jQuery =$;
var Isotope      = require('isotope-layout');

require('owl.carousel');
require('jquery-match-height');


module.exports = function(ctx, next){ 
  ////console.log("pageInitPortfolio called");
  /*var $grid = $('.grid');
  if($grid.get().length > 0){
    var iso = new Isotope( '.grid', {
      itemSelector: '.grid-item',
      percentPosition: true,
    });

    var $filter_buttons = $('.filter-button-group');

    // filter items on button click
    $filter_buttons.on( 'click', 'li', function() {
      var filterValue = $(this).attr('data-filter');
      var currentButton = $(this);
      iso.arrange({ filter: filterValue });
      $($filter_buttons).trigger('filtered', [currentButton]);
    });

    $filter_buttons.on('filtered', function(e, currentButton){
      $(this).find('li').each(function(i){
        $(this).removeClass('active');
      });

      currentButton.addClass('active');
    });
   
    iso.on('layoutComplete', function(){
      $grid.addClass('grid-ready');
    });

    iso.layout();
    iso.layout();

  }*/

  $('.grid .item').matchHeight(); 
  
  $(document).ready(function(){
    var $owl = $('.portfolio-slider');
    $owl.on('initialized.owl.carousel',  function(e){
      var carousel = $(e.target);
      carousel.find('.slide').fadeIn('fast');
    });

    var $owl = $('.portfolio-slider').owlCarousel({
      items: 1,
      loop:true,
      margin:0,
      nav:false
    });

  });
};
