var $ = require('jquery');
var $html = $('html');
require('./modernizr');

module.exports.killEvents = function(ctx, next) {
  //remove events
  $(document).off('mousewheel DOMMouseScroll');
  $(document).off('keyup');
  $(document).swipe('destroy');
  next();
}

module.exports.shuffle = function(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

module.exports.getBestSupportedVideoFormat = function(){
  //first supported format would be prefered
  var $videoFormats = {'h264': 'mp4', 'webm': 'webm'}; 
  var supportedVideoFormat = '';
  
  //find supported format by iterating through the list of available video formats
  $.each($videoFormats, function(k, v){
    if(Modernizr.video[k] > ''){
      supportedVideoFormat = v;
      return false; //break $.each loop
    }
  });

  return (supportedVideoFormat > '') ? supportedVideoFormat : false; 
  
}
